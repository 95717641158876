.container {
  width: 600px;
  margin: 0 auto;
  padding: 0;
}

.poster-text {
  font-size: 13px;
  line-height: 1.2;
  color: #333;
}

img {
  width: 600px;
  margin-bottom: 5px;
}
footer {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
}
footer p {
  margin-bottom: 2px;
  font-size: 12px;
  color: #888;
  text-align: center;
}
a {
  text-decoration: none;
  color: #888;
  font-size: 12px;
  margin-right: 6px;
}
.beian {
    display: flex;
    justify-content: center;
    align-items: center;
}
.beian a:first-child {
    display: inline-flex;
    align-items: center;
}
.beian img {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: top;
  margin-top: 6px;
  margin-right: 4px;
}
.iframe iframe {
  width: 100%;
  height: 100vh;
}
